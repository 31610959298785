import React, { useEffect, useState } from 'react';
import './App.css';
import * as client from './lib/WebAuthN/client';
import { Contract, Provider, Signer, utils, Transaction } from "koilib";
import { RegistrationData } from "./utils/Interfaces";

const NICKNAMES_CONTRACT_ID = '1KXsC2bSnKAMAZ51gq3xxKBo74a7cDJjkR';
const RPC_NODE = 'https://harbinger-api.koinos.io';
const KONDOR_FREE_MANA = '1K6oESWG87m3cB3M2WVkzxdTr38po8WToN';
const SMARTL_WALLET_WASM_URL = '/sw/smartwallet.wasm';
const SMARTL_WALLET_ABI_URL = '/sw/smartwallet-abi.json';
const DEFAULT_REDIRECT_URI = 'https://keepoke.com/signup';

function App() {
    const [available, setAvailable] = useState(false);
    const [nickname, setNickname] = useState('');
    const [address, setAddress] = useState('');
    const [logs, setLogs] = useState<Array<string>>([]);
    const [redirectUri, setRedirectUri] = useState(DEFAULT_REDIRECT_URI);

    const log = (line: string) => {
        setLogs(oldArray => [...oldArray, line]);
    }

    const checkAvailable = async () => {
        //const localA = await client.isLocalAuthenticator();
        //console.log(localA);
        const av = client.isAvailable();
        setAvailable(av);
    }

    const getRegistration = async (challenge: string): Promise<RegistrationData> => {
        const registration = await client.register(nickname, challenge, {
            authenticatorType: "auto",
            userVerification: "required",
            timeout: 60000,
            attestation: false,
            userHandle: "random 64 bytes",
            debug: false
        });
        log(JSON.stringify(registration));
        return {
            credential_id: registration.credential.id,
            public_key: registration.credential.publicKey
        };
    }


    const create = async () => {
        try {
            const provider = new Provider([RPC_NODE]);
            const privateKey = crypto.getRandomValues(new Uint8Array(32));
            const signer = new Signer({ privateKey: new Uint8Array(privateKey) });

            setAddress(signer.getAddress());

            signer.provider = provider;

            const available = await checkNickname(signer);
            if (!available) {
                log(`${nickname} not available`);
                return;
            }

            const challenge = await provider.getNextNonce(signer.getAddress());
            const abi = await (await fetch(SMARTL_WALLET_ABI_URL)).json();
            const wasm = await fetch(SMARTL_WALLET_WASM_URL);

            const registrationData = await getRegistration(challenge);
            if (!registrationData) {
                log(`${nickname} not uploaded`);
                return;
            }

            const contract = await buildContract(signer, abi, wasm);

            const { operation: op1 } = await registerNickname(signer);
            const { operation: op2 } = await uploadContract(contract);
            const { operation: op3 } = await register(contract, registrationData);

            const tx = await Transaction.prepareTransaction({
                operations: [op1, op2, op3],
            }, provider, KONDOR_FREE_MANA);

            const result = await signer.sendTransaction(tx);
            if (result.transaction) {
                log('wait');
                await result.transaction.wait();
                log('mined');
            }
        } catch (e) {
            console.error(e);
        }
    }

    const buildContract = async (signer: Signer, abi: any, wasm: Response): Promise<Contract> => {
        const bytecode = new Uint8Array(await wasm.arrayBuffer());
        const contract = new Contract({ signer, provider: signer.provider, bytecode, abi });
        contract.options.onlyOperation = true;
        return contract;
    }

    const uploadContract = async (contract: Contract) => {
        log(`uploading contract ${contract.signer?.getAddress()}`);
        return await contract.deploy({
            abi: JSON.stringify(contract.abi),
            authorizesCallContract: true,
            authorizesTransactionApplication: true,
            authorizesUploadContract: false
        });
    }

    const registerNickname = async (signer: Signer) => {
        log(`registering nickname ${nickname}`);
        const contract = new Contract({
            id: NICKNAMES_CONTRACT_ID,
            provider: signer.provider,
            signer
        });
        contract.options.onlyOperation = true;

        await contract.fetchAbi();

        const token_id = '0x' + utils.toHexString(new TextEncoder().encode(nickname));
        const to = signer.getAddress();

        return await contract.functions.mint({ to, token_id });
    }

    const register = async (contract: Contract, registrationData: RegistrationData) => {
        log(`registering credential id ${registrationData.credential_id}`);

        return await contract.functions.register({
            credential_id: registrationData.credential_id,
            public_key: registrationData.public_key,
        });
    }

    const checkNickname = async (signer: Signer) => {
        log(`checking nickname ${nickname}`);
        const nicknamesContract = new Contract({
            id: NICKNAMES_CONTRACT_ID,
            provider: signer.provider,
            signer
        });

        await nicknamesContract.fetchAbi();

        try {
            await nicknamesContract.functions.verify_valid_name({
                value: nickname
                //metadata
            });

            return true;
        } catch (e) {
            return false;
        }
    }

    useEffect(() => {
        checkAvailable();

        const redirectUriParam =  new URLSearchParams(new URL(window.location.href).search).get('redirectUri');
        if (redirectUriParam) {
            setRedirectUri(redirectUriParam);
        }
    }, [])

    return (
        <div className="App">
            {window.location.href}

            {available &&
                <p>client available</p>
            }

            {!available &&
                <p>client not available</p>
            }

            <input type="text" value={nickname} onChange={(e) => setNickname(e.target.value)} />

            <button onClick={create}>register</button>

            <a href={`${redirectUri}?nickname=${nickname}&address=${address}`}>Back to app</a>

            {logs.map(l => 
                <p>{l}</p>
            )}
        </div>
    );
}

export default App;
